html,
body {
  font-family: "Roboto";
  margin: 0;
  height: 100%;
}

.items {
  width: 100%;
  margin: 5vh 0 10vh;
}

.items--empty {
  font-family: "Dosis", sans-serif;
  font-size: 24px;
}

.items__card__price {
  font-weight: 500;
  font-size: 24px;
  color: #61b161;
  margin: 0 0 5px 5px;
}

.price-range {
  font-size: 14px;
  width: 100%;
}

.continue-shopping {
  margin-right: 8px;
}

.nav {
  background: #5e0068;
}

.nav__top {
  border-bottom: solid 3px #fcba87;
}
.nav__bottom {
  border-top: solid 3px #fcba87;
}

.nav__bottom__cart {
  background-color: #c9202f;
}

.modal__focusTxt {
  font-weight: 600;
}

.title__text {
  font-size: 35px;
  color: #5e0068;
  text-shadow: 3px 2px 3px rgba(58, 31, 54, 0.3);
  font-family: "Cinzel", serif;
}

.title__text--sub {
  font-size: 24px;
  font-family: "Cormorant", serif;
  font-weight: 100;
}

.title {
  text-align: center;
  margin: 24px auto 5vh;
}

.title--dropped {
  text-align: center;
  margin: calc(1vh + 64px) auto 5vh;
}

.nav__bottom__copyright {
  font-weight: 300;
  font-size: 14px;
}

.checkout__paymentBtn {
  float: right;
  margin: 1.5rem calc(3rem - 24px) 20px !important;
}

.checkout__shipping__subAddress {
  margin-right: 15px !important;
}

.checkout__sectionBtn,
.actionBtn {
  margin: 3rem !important;
  float: right;
}

.checkout__paymentBtn--loader,
.actionBtn--loader {
  margin-left: 1em;
}
.checkout__payment__review--left {
  margin-right: 20px;
}
.checkout__cart--empty {
  font-size: 24px;
  font-weight: 100;
  margin: 30px;
}

.checkout__cart__delete {
  color: #656060;
  font-weight: 300;
  font-size: 13px;
  text-align: center;
}

.checkout__payment__form {
  width: 100%;
}

.checkout__payment__form--shipping {
  margin-top: 24px;
}

.checkout__payment__form--container {
  width: 80%;
  margin: 0 5%;
  padding: 5%;
}

.clearfix {
  overflow: auto;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
.checkout--hide {
  display: none !important;
}
.checkout__payment__review--container {
  margin: 0 5%;
}

.checkout__payment__review__shipping__time {
  font-style: italic;
  margin: -3px 0 3px;
}

.success__subtxt {
  font-size: 15px;
  margin-top: 7px;
}

.checkout__payment__review {
  justify-content: flex-end;
  display: flex;
}
.checkout__payment__review--right {
  margin-right: 10px;
  font-weight: 600;
}
.checkout__payment__panel {
  display: block !important;
}

.checkout__payment__review__expand--hidden {
  visibility: hidden;
}

.orderStatus__arrow {
  text-align: center;
  margin-top: 20px;
}
.orderStatus__form {
  margin: 50px;
}

.orderStatus__results {
  width: 100%;
  font-size: 30px;
  font-weight: 100;
  text-align: center;
}

.orderStatus__results--status {
  font-size: 35px;
  margin-top: 10px;
  font-family: "Dosis", sans-serif;
}

.menu__title {
  font-weight: 100;
  font-style: italic;
  margin-top: 12px;
}

/* total width */
.scrollbar::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
  background-color: #fff;
  display: none;
}
.scrollbar::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(223, 223, 223);
  /* display: none; */
  border-radius: 16px;
  border: 5px solid #fff;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
}

/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
  display: none;
}

/* Override time picker */
.react-time-picker__wrapper {
  border: none;
}

@media only screen and (max-width: 600px) {
  .checkout__cart__delete--desktopOnly {
    display: none !important;
  }

  .MuiSnackbarContent-root {
    margin-bottom: 20px !important;
  }

  .title__text {
    font-size: 35px;
  }

  .checkout-container {
    display: block !important;
  }
  .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight.MuiPaper-elevation16 {
    max-width: 100%;
  }

  .title__shipping {
    left: 16px;
  }
}
